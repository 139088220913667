import { useState } from "react";

import { IconButton, TextField } from "@mui/material";
import ArrowForward from "@mui/icons-material/ArrowForward";

import "./App.css";

function App() {
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);

  function handleEmailChange(e) {
    setEmail(e.target.value);
  }

  async function handleEmailSubmit(e) {
    e.preventDefault();
    setSubmitted(true);
    await fetch("https://handle-email-submit-tm2b7pck7q-uc.a.run.app", {
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
      }),
      method: "POST",
    });
  }

  return (
    <div className="relative">
      <img
        src="/semitrailer.png"
        alt="Background image"
        className="absolute w-full h-full z-[-1]"
      />
      <div className="absolute w-full h-full z-[-1] bg-white/[.1] backdrop-blur-xl" />
      <img
        src="/logo192.png"
        className="mx-auto h-[130px] sm:mx-0"
        alt="logo"
      />
      <div className="w-full flex items-center justify-center px-8 sm:px-0">
        <div className="flex flex-col items-center justify-center">
          <div className="flex mx-auto flex-col gap-8 max-w-xl">
            <p className="text-3xl font-bold tracking-tight text-center font-bold sm:text-4xl">
              A new TMS solution to manage your trucking business in the modern
              day.
            </p>
            <p className="mt-6 text-lg leading-8 text-gray-800 text-center">
              Easily manage your entire trucking business. Let us handle the
              boring parts so you can get back on the road.
            </p>
            <div className="flex flex-col items-center mt-8 mb-8">
              {!submitted && (
                <p className="font-bold text-[#05ad86] mb-2">
                  Enter your email for a 1 month free trial
                </p>
              )}
              {!submitted && (
                <form className="email-form" onSubmit={handleEmailSubmit}>
                  <TextField
                    autoFocus
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleEmailSubmit}
                        >
                          <ArrowForward />
                        </IconButton>
                      ),
                    }}
                    onChange={handleEmailChange}
                    placeholder="Email"
                    type="email"
                    variant="outlined"
                  />
                </form>
              )}
              {submitted && (
                <div className="flex flex-col bg-white/30 p-4 rounded-lg">
                  <div className="thanks-text">Thanks.</div>
                  <div className="notify-text">
                    We will reach out to set up your free trial
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="max-w-7xl mt-16 flow-root sm:mt-16 mb-12">
            <div className="-m-2 rounded-xl bg-gray-900/20 p-2 ring-1 ring-inset ring-gray-900/10 lg:-m-4 lg:rounded-2xl lg:p-4">
              <img
                src="/app-screenshot.png"
                alt="App screenshot"
                className="rounded-lg shadow-2xl ring-1 ring-gray-900/10"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
